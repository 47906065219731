import {Login} from 'models'
interface Action {
    payload: Login | null,
    type: string
}

function user(state: Login | null = null, action: Action): Login | null {
	switch(action.type) {
		case 'SET_USER':
            return action.payload;
        case 'REMOVE_USER':
            return null;
        default:
        	return state;
	}
}

export default user;