// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-comun-coverages-tsx": () => import("./../../../src/pages/comun-coverages.tsx" /* webpackChunkName: "component---src-pages-comun-coverages-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoices-tsx": () => import("./../../../src/pages/invoices.tsx" /* webpackChunkName: "component---src-pages-invoices-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-plans-and-coverage-tsx": () => import("./../../../src/pages/plans-and-coverage.tsx" /* webpackChunkName: "component---src-pages-plans-and-coverage-tsx" */),
  "component---src-pages-providers-tsx": () => import("./../../../src/pages/providers.tsx" /* webpackChunkName: "component---src-pages-providers-tsx" */)
}

